import Link from "next/link";
// import Image from "next/image";

interface NavLinkLogoProps {
  className?: string;
}

const NavLinkLogoWhite: React.FC<NavLinkLogoProps> = ({className}) => {
  return (
    <Link href="/" className={`group ${className || ""}`}>
      <div className="flex items-center">
        {/* Logo on the left */}

        {/* Text on the right */}
        {/* <Image src="/img/tonbrains_logo_2.png" alt="TON BRAINS Logo" width={35} height={35} /> */}
        <span className="logo-mozg-white text-white  hover:text-primary transition-colors">TON BRAINS</span>
      </div>
    </Link>
  );
};

export default NavLinkLogoWhite;
